<template>
  <el-dialog
    :title="'查看详情'"
    :visible.sync="show"
    :close-on-click-modal="false"
    v-loading="loading"
    width="700px"
    @open="onOpen"
    @closed="closed"
  >
    <div
      v-if="session"
      style="width: 100%; height: 100%; max-height: 600px; overflow-y: auto"
    >
      <div style="display: flex; flex-direction: row">
        <div>场次：</div>
        <div>
          <!-- <span v-if="matchType != 'league'">{{ `第${session.group}组` }}</span>
          {{ `第${session.index}场` }} -->
          {{ session.session_name }}
        </div>
      </div>
      <div style="display: flex; flex-direction: row; margin-top: 10px">
        <div>版型：</div>
        <div>{{ session.pattern }}</div>
      </div>
      <div style="display: flex; flex-direction: row; margin-top: 10px">
        <div>时间：</div>
        <div>
          {{
            `${new Date(session.start_time).format(
              "yyyy年MM月dd日 hh:mm"
            )} - ${new Date(session.end_time).format("hh:mm")}`
          }}
        </div>
      </div>
      <div style="margin-top: 10px">
        <div>参赛门派</div>
        <div>
          <!-- 参赛队伍信息 -->
          <el-table :data="groups" border style="margin-top: 5px">
            <el-table-column label="门派ID" width="200">
              <template slot-scope="scope">
                {{ scope.row.sect_id }}
              </template>
            </el-table-column>
            <el-table-column label="门派名称">
              <template slot-scope="scope">
                {{ scope.row.sect_name }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div v-if="result">
        <div style="display: flex; flex-direction: row; margin-top: 10px">
          <div>比赛结果：</div>
          <!-- 比赛结果： -->
          <div>{{ matchResult }}</div>
        </div>
        <div v-if="getGroup('1').length > 0" style="margin-top: 10px">
          <div>神民阵营</div>
          <div>
            <el-table :data="getGroup('1')" border style="margin-top: 5px">
              <el-table-column label="门派ID" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sect_id }}
                </template>
              </el-table-column>
              <el-table-column label="门派名称">
                <template slot-scope="scope">
                  {{ scope.row.sect_name }}
                </template>
              </el-table-column>
              <el-table-column label="获得积分" width="200">
                <template slot-scope="scope">
                  {{ scope.row.score }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="getGroup('2').length > 0" style="margin-top: 10px">
          <div>狼人阵营</div>
          <div>
            <el-table :data="getGroup('2')" border style="margin-top: 5px">
              <el-table-column label="门派ID" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sect_id }}
                </template>
              </el-table-column>
              <el-table-column label="门派名称">
                <template slot-scope="scope">
                  {{ scope.row.sect_name }}
                </template>
              </el-table-column>
              <el-table-column label="获得积分" width="200">
                <template slot-scope="scope">
                  {{ scope.row.score }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div v-if="getGroup('3').length > 0" style="margin-top: 10px">
          <div>第三方阵营</div>
          <div>
            <el-table :data="getGroup('3')" border style="margin-top: 5px">
              <el-table-column label="门派ID" width="200">
                <template slot-scope="scope">
                  {{ scope.row.sect_id }}
                </template>
              </el-table-column>
              <el-table-column label="门派名称">
                <template slot-scope="scope">
                  {{ scope.row.sect_name }}
                </template>
              </el-table-column>
              <el-table-column label="获得积分" width="200">
                <template slot-scope="scope">
                  {{ scope.row.score }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "session_detail",
  emits: ["close"],
  model: {
    prop: "visible",
    event: "update:visible",
  },
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      required: true,
    },
    session_id: {
      type: String,
      required: true,
    },
    match_id: {
      type: String,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    matchResult: function() {
      //最终结果
      switch (this.result.result + "") {
        case "1":
          return "神民阵营胜利";
        case "2":
          return "狼人阵营胜利";
        case "3":
          return "第三方阵营获胜";
        default:
          return "";
      }
    },
  },
  watch: {
    $route: {
      handler: function(val) {
        this.type_id = val.query.type_id;
        this.matchType = localStorage.getItem("matchType");
      },
      immediate: true,
    },
  },
  data() {
    return {
      matchType: "", //赛事类型
      type_id: "",
      loading: false,
      session: false,
      groups: [],
      result: false,
      // battleVisible: true,
    };
  },
  methods: {
    //对以前代码进行注释
    getGroup(camp) {
      //参赛结果 得到的阵营是否和参数要的阵营是否一致，返回结果
      let detail = this.result.detail.filter((r) => r.camp == camp);
      return detail.map((r) => {
        //当队伍的结果和录入的结果一致的时候则显示
        let group = this.groups.find((g) => g.sect_id == r.sect_id);
        if (group != undefined) {
          return {
            sect_id: group.sect_id,
            sect_name: group.sect_name,
            score: r.score,
            camp: r.camp,
          };
        }
      });
    },
    closed() {
      //todo: 有时间改为对象，false的判断新增一个专门的字段
      this.result = false;
      this.$emit("close");
    },
    //得到结果
    async getResult() {
      try {
        this.loading = true;
        let { data, errorCode } = await this.$http.match.stage_session_result({
          match_id: this.match_id,
          session_id: this.session_id,
        });
        this.loading = false;
        if (errorCode != "0000") {
          this.show = false;
          return;
        }
        //拿到头信息
        this.session = data.session;
        //队伍信息
        this.groups = data.groups;

        if (data.result) {
          this.result = {
            result: data.result.result,
            video_url: data.result.video_url,
            //具体信息
            detail: data.result.battle_result,
          };
        }
      } catch (error) {
        this.loading = false;
      }
    },
    /** 弹窗打开 */
    onOpen() {
      this.getResult();
    },
  },
};
</script>

<style scoped></style>
